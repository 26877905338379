import React, { useEffect, useState } from "react";
import consultant1 from '../Assets/blank-profile-pic.png';
import consultant2 from '../Assets/rich-gleason.png';

import frederick from '../Assets/frederick-headshot.jpg';
import sarah from '../Assets/sarah-headshot.jpg';
import jason from '../Assets/jason-headshot.jpg';
import daisyImg from '../Assets/daisy.jpg';

import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function Consulting(){
    const ref = useRef(null);
    const isInView = useInView(ref);
    const [hasAnimated, setHasAnimated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isInView && !hasAnimated) {
        setHasAnimated(true);
        }
    }, [isInView, hasAnimated]);


    const [contactDate, setContactDate] = useState('');

    useEffect(() => {
        let todayDate:Date = new Date();
        todayDate.setDate(todayDate.getDate() + 1); // set date to tomorrow

        let dd = String(todayDate.getDate()).padStart(2, '0');
        let mm = String(todayDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = todayDate.getFullYear();

        const tomorrowDateStr:string = `${yyyy}-${mm}-${dd}`;

        setContactDate(tomorrowDateStr);
    }, []);
    return(
        <div className="consulting">
            {/* Navbar Section */}
            {/* <nav className="navbar">
                <div>
                    <img src={backArrowImg} />
                    <span>Homepage</span>
                </div>
                <img src={ghgLogo} />
                <p 
                onClick={()=>{
                    window.scrollTo(0, 0)
                }}
                >Return To Top Of Page</p>
            </nav> */}
            {/* Hero Section */}
            <section id="hero" className="hero">
                {/* <motion.div 
                ref={ref}
                initial={{ opacity: 0 }}
                animate={{ opacity: isInView ? 1 : 0 }}
                transition={{ duration: 2.5 }}
                className="hero__image-container">
                <img className='hero-ghg-logo' src={ghgLogo} alt="Gleason Consulting Logo" />
                <h1>Gleason Holdings Group</h1>
                </motion.div> */}
                <div className="hero__content">
                <img className="daisy" src={daisyImg} />
                <h2>Grow With Gleason</h2>
                <p className="hero__paragraph">
                    Gleason Holdings Group is proud to offer <b style={{ display: 'inline', color: '#270e0c'}}>Gleason Consulting</b>. We pride ourselves on being a trusted partner for businesses for over 5 years seeking expertise in optimizing their supply chain operations. 
                    Through our expert approach to problem-solving, we empower our clients to not only address specific pain points but also drive lasting transformation and growth. 
                    Whether it's navigating the intricacies of supply chain management or simply seeking a fresh perspective on how to operate more efficiently and effectively, businesses turn to Gleason Consulting because they know that they will receive tailored solutions delivered with leading expertise.
                </p>
                </div>
            </section>
            
            {/* Our Consultants Section */}
            <section id="consultants" className="consultants">
                <h2>Meet Our Founder</h2>
                <div className="consultant-item">
                    <img className='consultant-img' src={consultant2} alt="Consultant 2" />
                    <h3>Richard Gleason</h3>
                    <p>
                        For over two decades, Richard has been a trusted advisor in supply chain operations. He's overseen facilities 
                        management, logistics, and fleet management, always looking for ways to streamline processes and reduce costs. With his 
                        extensive experience and expertise, he helps companies like yours achieve operational excellence.
                    </p>
                </div>
                <h2>Meet Our IT Consultant</h2>
                <div className="consultant-item">
                    <img className='consultant-img' src={consultant1} alt="Consultant 1" />
                    <h3>Jon Martin</h3>
                    <p>
                        ...
                    </p>
                </div>
            </section>

            {/* Services Section */}
            <section id="services" className="services">
                <h2>Our Consulting Services</h2>
                <div className="services-wrapper">
                <div className='col-lg-6'>
                    <h2><u>Supply Chain Management</u></h2>
                    <div className="service-item">
                    <h3>Material Handler Productivity Improvement</h3>
                    <p>Our supply chain management services can help you increase productivity in your material handling operations by identifying bottlenecks and streamlining processes</p>
                    </div>
                    <div className="service-item">
                    <h3>Supply Chain Optimization</h3>
                    <p>We offer procurement consulting services that can help you optimize your supply chain, reducing costs and improving efficiency</p>
                    </div>
                    <div className="service-item">
                    <h3>Cost Recovery Services in the Supply Chain</h3>
                    <p>Our supply chain management services can help you identify and address inefficiencies in your supply chain to reduce overall costs</p>
                    </div>
                    <div className="service-item">
                    <h3>Material Cost Savings and Optimization</h3>
                    <p>Our supply chain management services can help you identify the most cost-effective ways to purchase materials for your business</p>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <h2><u>Information Technology (IT)</u></h2>
                    <div className="service-item">
                        <h3>Coming Soon</h3>
                        <p>...</p>
                    </div>
                    <div className="service-item">
                        <h3>Coming Soon</h3>
                        <p>...</p>
                    </div>
                    <div className="service-item">
                        <h3>Coming Soon</h3>
                        <p>...</p>
                    </div>
                    <div className="service-item">
                        <h3>Coming Soon</h3>
                        <p>...</p>
                    </div>
                </div>
                </div>
                {/* <div className='col-lg-6'>
                    <h2><u>Digital Privacy and Security</u></h2>
                    <div className="service-item">
                    <h3>Password and Account Security</h3>
                    <p>Our Digital Privacy Specialist provides digital account security solutions to help protect sensitive information and maintain data confidentiality.</p>
                    </div>
                    <div className="service-item">
                    <h3>Phishing Awareness and Prevention</h3>
                    <p>We offer training and support for businesses to identify and prevent phishing attacks, ensuring a safe online environment for employees and customers.</p>
                    </div>
                    <div className="service-item">
                    <h3>Online Reputation Management</h3>
                    <p>Our digital privacy specialist helps companies manage their online reputation by developing strategies to improve brand visibility and reduce negative reviews.</p>
                    </div>
                    <div className="service-item">
                    <h3>Social Media Security</h3>
                    <p>We provide social media security services to help protect sensitive information and maintain brand consistency across various social media platforms.</p>
                    </div>
                </div> */}
            </section>

            {/* Testimonial Section */}
            <section id="testimonials" className="testimonials">
                <h2 className="section-header">What Our Clients Say</h2>
                <div className="testimonials-container">
                {/* New testimonial boxes with placeholder images */}
                <div className="testimonial-box">
                    <em className="testimonial-text">"We've seen tremendous improvements in productivity and efficiency since partnering with Gleason Consulting to optimize our 
                        supply chain. Richard's expertise and guidance has allowed us to reduce costs, streamline processes, and focus on what 
                        matters most - growing our business. We couldn't be happier with the results!"
                    </em>
                    <div className="testimonial-info">
                    <img className="testimonial-img" src={sarah} alt="Sarah W." />
                    <h3 className="client-name">Sarah W.</h3>
                    <h4>Chief Security Officer</h4>
                    <p>Richmond, VA</p>
                    </div>
                </div>
                <div className="testimonial-box">
                    <em className="testimonial-text">"The support and expertise in supply chain management services my company received were instrumental in helping us improve our efficiency and cost effectiveness. We are particularly impressed with Richard's ability to identify and address inefficiencies, which has led to significant cost savings."</em>
                    <div className="testimonial-info">
                    <img className="testimonial-img" src={frederick} alt="Frederick B." />
                    <h3 className="client-name">Frederick B.</h3>
                    <h4>Chief Supply Chain Officer</h4>
                    <p>Newark, NJ</p>
                    </div>
                </div>
                <div className="testimonial-box">
                    <em className="testimonial-text">"Richard's expertise in supply chain management services has been invaluable to our business. His dedication to ensuring that we are always operating within cost constraints and meeting deadlines is truly commendable."</em>
                    <div className="testimonial-info">
                    <img className="testimonial-img" src={jason} alt="Jason R." />
                    <h3 className="client-name">Jason R.</h3>
                    <h4>Director of Operations</h4>
                    <p>Providence, RI</p>
                    </div>
                </div>
                </div>
            </section>

            <section id="contact-us" className="contact-us">
                <h2 className='text-center'>Contact Us</h2>
                {/* Contact Form */}
                <form>
                    <p>To learn more and request a <b>FREE</b> Microsoft Teams virtual consultation, please feel free to contact us using the form below.
                    <br />
                    <br />One of our representives will respond to your inquiry within 24-48 hours.</p>
                    <b>* Our minimum rate for the aforementioned consulting services is $175 per hour with a minimum booking requirement of 4 hours. Your company must reimburse consultant for any and all reasonable travel-related expenses incurred by the consultant in connection with the consultant's performance of the services requested. </b>
                    <section className="availability-calendar">
                        <h3>Select Availability</h3>
                        <div className="calendar-popup">
                        <input value={contactDate} onChange={(e)=>{setContactDate(e.target.value)}} type="date" id="datepicker" />
                        <select name="time" id="time">
                            <option value="">--Please choose a time--</option>
                            <option value="09:00">9:00 AM EST</option>
                            <option value="10:00">10:00 AM EST</option>
                            <option value="11:00">11:00 AM EST</option>
                            <option value="1:00">1:00 PM EST</option>
                            <option value="2:00">2:00 PM EST</option>
                            <option value="3:00">3:00 PM EST</option>
                            {/* Add more options for times */}
                        </select>
                        </div>
                    </section>
                    <div className='message-contact-info'>
                        <label>Name</label>
                        <input type="text" placeholder="Your name" required/>
                        <label>Your Company Name (Optional)</label>
                        <input type="text" placeholder="Your company name" />
                        <label>Phone Number</label>
                        <input type='text' placeholder='Your phone number' required/>
                        <label>Email</label>
                        <input type="email" placeholder="Your email address" required/>
                        <label>Message</label>
                        <textarea placeholder="Enter your message here" required/>
                    </div>
                    <button type="submit" onClick={()=>{alert("We have received your request! A representive will get back to you within 24-72 hours.")}}>Send Message</button>
                </form>
            </section>
      </div>
    )
}