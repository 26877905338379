import React, { useEffect, useState } from 'react';
import './App.css';

import { motion, useInView } from "framer-motion";
import { useRef } from "react";

import ghgLogo from './Assets/logo-cropped.svg';
import consultingImg from './Assets/consulting-home.jpg';
import { useNavigate } from 'react-router-dom';
import Consulting from './Components/Consulting';

function App() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasAnimated, setHasAnimated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
      if (isInView && !hasAnimated) {
      setHasAnimated(true);
      }
  }, [isInView, hasAnimated]);

  return (
    <div className="App">
      <section id="hero" className="intro">
          <motion.div 
          ref={ref}
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{ duration: 2.5 }}
          className="hero__image-container">
            <img className='hero-ghg-logo' src={ghgLogo} alt="Gleason Consulting Logo" />
            <h1>Home of the Gleason Holdings Group</h1>
            <h3 style={{color: 'red', textAlign: 'center', padding: '0rem 1rem 0rem 1rem'}}>Please pardon our appearance! This website is currently migrating to our new look! At this time please use a mobile phone for the best experience.</h3>
          </motion.div>
          <div className="hero__content">
          {/* <p className="hero__paragraph">
          At <b style={{ display: 'inline', color: '#270e0c'}}>Gleason Consulting</b>, we pride ourselves on being a trusted partner for businesses seeking expertise in digital privacy and optimizing their supply chain operations. 
          Through our expert approach to problem-solving, we empower our clients to not only address specific pain points but also drive lasting transformation and growth. 
          Whether it's safeguarding sensitive information, navigating the intricacies of supply chain management, or simply seeking a fresh perspective on how to operate more efficiently and effectively, businesses turn to Gleason Consulting because they know that they will receive tailored solutions delivered with leading expertise.
          </p> */}
          </div>
      </section>
        
      {/* <section className='home-services-list'>
        <h2>How Can We Help Your Business Grow?</h2>
        <ul>
            <li onClick={()=>{
              navigate('/consulting');
              window.scrollTo(0, 0);
              }}
            >
              <img src={consultingImg} />
              <a>Consulting</a>
            </li>
        </ul>  
      </section> */}
      <Consulting />
      {/* Footer Section */}
      <footer id="footer" className="footer">
        <h3>How did we do?</h3>
        <p>
          We would love to hear about your experience! <a href='mailto:contact@gleasonholdings.com'>contact@gleasonholdings.com</a>
          <br />
          <ul style={{listStyle:'none', padding: 0}}>
            <h3>Our Hours:</h3>
            <li>Monday - Friday: 9 AM EST - 5 PM EST</li>
            <li>Saturday - Sunday: Closed</li>
            <li></li>
          </ul>
          950 Wheeler Rd, Hauppauge, NY 11788
          <br />
          <br />
          &copy; 2024 Gleason Holdings Group LLC. 
          <br />
          All rights reserved.
        </p>
        <ul className='legal'>
          <li><a href='/'>Refund Policy</a></li>
          <li><a href='/'>Privacy Policy</a></li>
          <li><a href='/'>Terms of Service</a></li>
        </ul>
      </footer>
      </div>
  );
}

export default App;
